import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import {Logo} from './Logo'
import IsOnline from '../../util/IsOnline'

const text = en

class Home extends React.Component {
  constructor (props) {
    super(props)
    this.gotoSettings = this.gotoSettings.bind(this)
    this.gotoReports = this.gotoReports.bind(this)
    this.gotoChoices = this.gotoChoices.bind(this)
  }

  componentDidMount () {
    this.props.initiate()
  }
  gotoChoices () {
    this.goto('/parameters/choices')()
  }

  gotoSettings () {
    this.goto('/settings/report')()
  }

  gotoReports () {
    this.goto('/reports')()
  }

  goto (href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  render () {
    return (
      <div className = "grid-main">
        <div className = "header-left">
          <span className = "secondary-color">Temp.</span>
          <span className = "primary-color bold">  20°C</span>
        </div>
        <div className = "header-right">
          <IsOnline isOnline = {this.props.isOnline}/>
          <div className = "primary-color">v5.0.0</div>
          <span className = "secondary-color">GPS : </span>
          <span className = "primary-color bold">  46.245216° 7.000683 559m</span>
        </div>
        <div className = "pos-logo">
          <Logo/>
        </div>
        <div className = "pos-bot pos-1">
          <div className="btn btn--flat" onClick={this.gotoSettings}> {text.settings} </div>
        </div>
        <div className = "pos-bot pos-2">
          <div className="btn btn--raised" onClick={this.gotoChoices}> {text.start} </div>
        </div>
        <div className = "pos-bot pos-3">
          <div className="btn btn--flat" onClick={this.gotoReports}> {text.reports} </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Home)
