import './style.scss'

import React from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {PdfIcon} from './pdficon.js'
import {WaitIcon} from './waiticon.js'
import {DoneIcon} from './doneicon.js'
import Graphs from '../recording/chart/Graphs'
import parametersTextEn from '../../texts/parameters-en.json'
import {dateToString} from '../../util/tools'

import fileHelper from '../settings/file-helper'

const indexedDB = require('../../util/indexedDB')

const parametersText = parametersTextEn

const ductText = parametersText.duct
const machineText = parametersText.machine
const cableText = parametersText.cable
const generalText = parametersText.general

export class PdfButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgEncoded: '',
      width: 780,
      height: 460,
      icon: 'download',
      report: {}
    }
    this.makePdf = this.makePdf.bind(this)
    this.logContainerRef = this.logContainerRef.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  clearGraph() {
    this.setState({report: {data: null}})
  }

  logContainerRef(canvas) {
    this.setState({canvas})
  }

  async handleClick() {
    const reportId = this.props.reportId
    this.setState({icon: 'wait'})

    try {
      const fullReport = await indexedDB.getFullReportById(reportId)
      this.setState({report: fullReport})
      this.makePdf()
    } catch (error) {
      console.error('The report is not in IndexedDB.', error)
      this.clearGraph()
    }
  }

  makeCanvas() {
    const canvas = document.createElement('canvas')
    const resolution = 2
    canvas.width = this.state.width * resolution
    canvas.height = this.state.height * resolution
    return canvas
  }

  makePdf() {
    console.log('makePdf()')

    const canvas = this.state.canvas

    var imgData = canvas.toDataURL('image/png')
    console.log('toDataURL done')

    const {settings} = this.props
    const report = this.state.report
    const {parameters} = report
    const {general, duct, machine, cable} = parameters
    const date = report.reportDate.split('.')[0].split(':').join('')
    const pdfname = `${date}_${report.reportId}.pdf`
    const rawData = report.data ? report.data : []
    const wimg = 187
    const himg = 110
    let doc = new jsPDF('p','mm','a4')
    let totalPagesExp = '{total_pages_count_string}'

    const variablesToReport = [
      'force', 'blowing', 'speed', 'distance', 'comment', 'time'
    ]

    const data = rawData.map( measure => {
      const newMeasure = {}
      variablesToReport.map( (variable) => {
        // newMeasure[variable] = measure[variable] ? measure[variable] : "0";
        newMeasure[variable] = measure[variable];
      })
      return newMeasure
    })

    doc.autoTable({
      startY: 10,
      styles: {
        overflow: 'ellipsize',
        lineColor: [255, 255, 255],
        fillColor: [255, 255, 255],
        lineWidth: 0.3,
        fontSize: 8
      },
      headStyles: {
        textColor: [0, 0, 0],
        valign  : 'middle',
        fontSize: 12
      },
      fillColor: [255, 255, 255],
      alternateRowStyles: {
        fillColor: [255, 255, 255]
      },
      head : [
        ["", "INSUFFLATION PROTOCOL", ""]
      ],
      body : [
        [
          settings.client.adressclient+"\n"+settings.client.emailclient,
          ""
        ],
      ]
    })

    const logo = this.props.settings.client.logo
    const size = 20
    doc.addImage(logo, 'PNG', 180, 17, size, size, "logo", 'NONE')

    doc.autoTable({
      startY: 43,
      styles: {
        overflow: 'ellipsize',
        lineColor: [236, 236, 236],
        lineWidth: 0.3
      },
      fillColor: [255, 255, 255],
      alternateRowStyles: {
          fillColor: [255, 255, 255]
      },
      body : [
        [
          generalText.project + ": "+general.project,
          generalText.client + ": "+ parameters.general.client,
          dateToString(report.reportDate, 'en')
        ],
        [
          generalText.section + ": "+general.section,
          generalText.operator + ": "+ general.operator,
          generalText.remarks + ": "+ general.remarks,
        ],
        [
          ductText.title,
          cableText.title,
          machineText.title
        ],
        [
          ductText.supplier + ": "+duct.supplier,
          cableText.supplier+ ": "+cable.supplier,
          "Machine: Optijet"
        ],
        [
          ductText.type+": "+duct.type,
          cableText.type+": "+cable.type,
          "serie n°: "+settings.machine.serienumber
        ],
        [
          ductText.intDiameter+": "+duct.intDiameter,
          cableText.cableDiameter+": "+cable.cableDiameter,
          machineText.compressor+ ":  "+machine.compressor
        ],
        [
          ductText.innerLayer+": "+duct.innerLayer,
          cableText.cable+": "+cable.cable,
          machineText.lubricant+": "+machine.lubricant
        ],
        [
          ductText.installedIn+": "+duct.installedIn,
          cableText.amountOfFiber+": "+cable.amountOfFiber,
          machineText.lubricator+": "+machine.lubricator
        ],
        [
          ductText.configuration+": "+duct.configuration,
          cableText.reel+": "+cable.reel,
          machineText.aftercooler+": "+machine.aftercooler
        ],
        [
          ductText.identification+": "+duct.identification,
          cableText.cableHead+": "+cable.cableHead,
          ' '
        ],
        [
          ductText.temperature+": "+duct.temperature,
          cableText.cableTemperature+": "+cable.cableTemperature,
          ' '
        ],
        [
          cableText.meteringAtStart+": "+cable.meteringAtStart,
          cableText.fmaxSource+": "+cable.fmaxSource,
          cableText.fmax+": "+cable.fmax
        ],
        [
          cableText.meteringAtEnd+": "+cable.meteringAtEnd,
          cableText.radialForce+": "+cable.radialForce,
          " "
        ]
      ],
      didParseCell: function(data) {
        if (data.row.index === 2) {
          data.cell.styles.fillColor = [236, 236, 236];
          data.cell.styles.fontSize = 12;
          data.cell.styles.halign = 'center';
        }
        if (data.row.index < 2) {
          data.cell.styles.lineColor = [255, 255, 255]
        }
      }
    })

    console.log('autoTable 0')


    if (rawData.length > 0) {
      doc.addImage(imgData, 'PNG', 10, 150, wimg, himg, "alias", 'NONE')
      console.log('imageAdded')
      doc.addPage('a4', 'p')
      doc.setFontSize(10)
      doc.autoTable({
        head: [{distance:'distance\n [m]',force:'force\n[N]',blowing:"blowing\n[bar]",speed:"Speed\n[m/min]",time:"Time\n[hh:mm:ss]", comment:"Comment"}],
        headStyles: {
          fillColor: [236, 236, 236],
          textColor: [0, 0, 0],
          halign  : 'center'
        },
        body: data,
        startY: 25,
        // tableLineColor: [0, 0, 0],
        // tableLineWidth: 0.6,
        styles: {
          overflow: 'ellipsize',
          cellWidth: 'wrap',
          lineColor: [236, 236, 236],
          lineWidth: 0.3,
          cellPadding: 1,
          fontSize: 8
        },
        fillColor: [250, 250, 250],
        alternateRowStyles: {
            fillColor: [255, 255, 255]
        },
        columnStyles: {
          comment: {
            cellWidth: 'auto'
          },
          distance:{
            halign: 'right'
          },
          force:{
            halign: 'right'
          },
          blowing:{
            halign: 'right'
          },
          speed:{
            halign: 'right'
          },
          time:{
            halign: 'right',
            cellWidth: 'auto'
          }
        },
        didDrawPage: function (data) {
          // Footer
          let str = "Page " + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            str = str+ " of " + totalPagesExp;
          }
          doc.setFontSize(8);
          const footer = "Plumettaz SA / Vetter GmbH Kabelverlegetechnik, Industriestrasse 28, D-79807 Lottstetten, Tel. +49 7745 9293-0, info@vetter-kabel.de \n"
          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          const pageSize = doc.internal.pageSize;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(footer+str, data.settings.margin.left, pageHeight - 10);
        },
        margin: {top: 30}
      })
      console.log('autoTable 1')
    }
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    console.log('about to save')
    doc.save(pdfname, {returnPromise: true})
      .then(() => {
        console.log('saved')
        this.setState({icon: 'done'})
        setTimeout(() => {
          this.props.reset()
        }, 500)
        this.clearGraph()
      })
      .catch((error) => {
        console.error('Saving failed.')
        console.error(error)
        this.clearGraph()
      })
  }

  render () {
    const report = this.state.report
    let Graph = ''

    const width = this.state.width * 1.6
    const height = this.state.height * 1.6
    
    if (report.data) {
      Graph = (
        <Graphs
          recordedData = {report.data}
          logContainerRef = {this.logContainerRef}
          width = {width}
          height = {height}
          needCrashTest = {report.isCrashTest}/>
      )
    }

    let Icon = <PdfIcon/>
    switch (this.state.icon) {
      case 'download':
        Icon = <PdfIcon/>
        break
      case 'wait':
        Icon = <WaitIcon/>
        break
      case 'done':
        Icon = <DoneIcon/>
        break
      default:
        Icon = <PdfIcon/>
    }
    return (
      <div onClick = {this.handleClick} className = "item-grid-report-line-fullright btn btn--pdf">
        {Icon}
        <div style={{width: `${width}px`, height: `${height}px`}}>
          {Graph}
        </div>
      </div>
    )
  }
}
