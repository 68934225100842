import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import parametersTextEn from '../../../texts/parameters-en.json'
import parametersSource from './param.json'
import Input from '../../../util/Input'
import {ButtonHome} from '../../../util/ButtonHome'
import IsOnline from '../../../util/IsOnline'

const text = en
const parametersText = parametersTextEn

class Form extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      categoryIndex: 0,
      categories: ['general', 'machine', 'duct', 'cable'],
      hrefBefore: '',
      hrefAfter: '',
      fieldsRequired: [],
      showRequiredFields: false
    }
    this.goto = this.goto.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoBefore = this.gotoBefore.bind(this)
    this.updateState = this.updateState.bind(this)
    this.gotoHome = this.gotoHome.bind(this)
    this.showRequiredFields = this.showRequiredFields.bind(this)
  }

  componentDidMount() {
    const category = this.props.match.params.category
    const categoryIndex = this.state.categories.indexOf(category)
    this.updateState(categoryIndex)
  }

  componentDidUpdate(prevProps, prevState) {
    const category = this.state.categories[this.state.categoryIndex]

    const parametersValue = this.props.parameters[category]
    const fieldsRequired = parametersSource[category].filter(param => {
      if(param.required) {
        if(!parametersValue[param.name] && parametersValue[param.name] !== 0) {return param}
      }
    })
    if (fieldsRequired.length !== this.state.fieldsRequired.length){
      this.setState({fieldsRequired})
    }
  }

  updateState(categoryIndex) {
    const categories = this.state.categories
    let hrefBefore = "/parameters/choices", hrefAfter = "/parameters/validation"
    if (categoryIndex +1 < categories.length) {
      hrefAfter = categories[categoryIndex+1]
    }
    if (categoryIndex > 0) {
      hrefBefore = categories[categoryIndex-1]
    }
    this.setState({categoryIndex, hrefBefore, hrefAfter, showRequiredFields: false})
  }

  gotoBefore () {
    this.goto(this.state.hrefBefore)
  }

  gotoNext () {
    this.goto(this.state.hrefAfter)
  }

  gotoHome () {
    this.props.history.push('/')
    this.props.updateLocation('/')
  }

  goto(href){
    let route = ''
    const categoryIndex = this.state.categories.indexOf(href)
    if (categoryIndex > -1) {
      route = '/parameters/form/' + href
    } else {
      route = href
    }
    this.updateState(categoryIndex)
    this.props.history.push(route)
    this.props.updateLocation(route)
  }

  showRequiredFields() {
    this.setState({showRequiredFields: true})
  }

  render() {
    const category = this.state.categories[this.state.categoryIndex]
    const parameters = this.props.parameters
    const {fieldsRequired, showRequiredFields} = this.state
    const Inputs = parametersSource[category].map(parameter => {
      let isRed = false
      if (showRequiredFields && fieldsRequired.indexOf(parameter) > -1) {
        isRed = true
      }
      let inputdata = parameter
      if (parameter.name === 'fmax') {
        if (parameters.cable.fmaxSource === 'CrashTest' && !parameters.cable.fmax) {
          inputdata.required = false
          inputdata.hide = true
        } else {
          inputdata.required = true
          inputdata.hide = false
        }
      }
      return (
        <Input
          inputdata = {inputdata}
          label = {parametersText[category][parameter.name]}
          value = {parameters[category][parameter.name]}
          handleChange = {((e) => this.props.updateParameter(e, category, parameter.type))}
          key = {category+parameter.name}
          isRed = {isRed}
        />
      )
    })

    let classToAddToNextButton = "disable"
    let actionGoNext = this.showRequiredFields

    if (this.state.fieldsRequired.length === 0) {
      classToAddToNextButton = ""
      actionGoNext = this.gotoNext
    }


    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div className = "header-center pos-align-bot">
          <h2 className = "secondary-color">{parametersText[category].title}</h2>
        </div>
        <div className = "content-center content--scrollable">
          {Inputs}
        </div>
        <div className = "footer-left pos-align-bot sticky-bot">
          <div className="btn btn--flat" onClick={this.gotoBefore}> {text.before} </div>
        </div>
        <div className = "footer-right pos-align-bot sticky-bot">
          <div className={"btn btn--raised "+classToAddToNextButton} onClick={actionGoNext}> {text.next} </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Form)
