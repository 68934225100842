const defaultLogo = require('./default-logo')

module.exports = {
  parameters: {
    general: {
      project: '',
      section: '',
      client: '',
      operator: '',
      remarks: ''
    },
    machine: {
      lubricator: true,
      lubricant: '',
      compressor: '',
      aftercooler: false
    },
    duct: {
      installedIn: '',
      type: '',
      intDiameter: 0,
      innerLayer: '',
      configuration: '',
      supplier: '',
      identification: '',
      temperature: 0
    },
    cable: {
      cable: '',
      type: '',
      amountOfFiber: 0,
      supplier: '',
      reel: '',
      cableDiameter: null,
      cableHead: false,
      meteringAtStart: 0,
      meteringAtEnd: 0,
      cableTemperature: 0,
      fmaxSource: 'From Datasheet',
      fmax: null,
      radialForce: 0
    }
  },
  settings: {
    appearence: {
      language: 'English'
    },
    client: {
      adressclient: 'HMS MACHINES B.V.\nMarconistraat 113\n2809 PG GOUDA\nPays-Bas',
      emailclient: 'www.hms-machines.nl',
      logo: defaultLogo
    },
    distributor: {
      adressdistributor: 'Plumettaz SA / Vetter GmbH Kabelverlegetechnik,\n Industriestrasse 28, \n D-79807 Lottstetten',
      emaildistributor: 'info@vetter-kabel.de'
    },
    machine: {
      serienumber: '0123456'
    }
  },
  recordingStatus: 'Pause',
  realTimeData: {
    distance: 0,
    speed:0,
    blowing:0,
    force:0,
    temperature:0,
    duration:0,
    clamping:0
  },
  currentLocation: '/',
  reportData: [],
  recordedData: [],
  report: {},
  isOnline: 'no',
  isNewMeter: false,
  needCrashTest: false,
  hasDifferentParameters: false,
  usedHeap: '0Mb'
}
