import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import {dateAndHoursToString} from '../../../util/tools'
import {ButtonHome} from '../../../util/ButtonHome'
import {DeleteButton} from '../../../util/DeleteButton.js'
import IsOnline from '../../../util/IsOnline'
import indexedDB from '../../../util/indexedDB'

const text = en

const ButtonFavorite = (props) => {
  const classToAdd = props.isDeleteSelected ? "warning" : ""
  const operator = props.data.general.operator.substring(0, 10) + '...'
  return (
    <div className = {"favbtn-style grid-favorite-button "+classToAdd} onClick={(() => props.handleClickFav(props.data))}>
      <span className = "item-tl text-medium">{props.data.name}</span>
      <span className = "item-bl">{props.data.date}</span>
      <span className = "item-br">{operator}</span>
      <span className = "item-mr pos-align-bot">{props.data.general.project}</span>
      <span className = "item-mr">
        <span className = "primary-color bold">Fmax = </span>
        <span>{props.data.cable.fmax} N</span>
      </span>
      <span className = "item-ml grid-cables">
        <div className = "duct"></div>
        <div className = "arrow-duct-left"></div>
        <div className = "arrow-duct-right"></div>
        <div className = "cable">
          <div className = "arrow-cable-left"></div>
          <span className = "pos-align-bot text-medium pos-justify-center">{props.data.cable.cableDiameter}</span>
          <span className = "pos-align-top pos-justify-center">mm</span>
          <div className = "arrow-cable-right"></div>
        </div>
        <div className = "duct-number">
          <span className = "pos-align-bot primary-color bold text-medium">{props.data.duct.intDiameter}</span>
          <span className = "pos-align-top primary-color">mm</span>
        </div>
      </span>
    </div>
  )
}

class Choices extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      favorites : [],
      isDeleteSelected : false
    }
    this.handleClickFav = this.handleClickFav.bind(this)
    this.gotoNew = this.gotoNew.bind(this)
    this.gotoHome = this.gotoHome.bind(this)
    this.selectDelete = this.selectDelete.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  async componentDidMount() {
    try {
      const rawFavorites = await indexedDB.getFavorites()
      const favorites = rawFavorites.map((favorite) => {
        favorite.date = dateAndHoursToString(favorite.date, 'en')
        return favorite
      })
      this.setState({favorites})
    } catch (error) {
      console.error('Choices::componentDidMount', error)
    }
  }

  gotoNew () {
    this.goto('/parameters/form/general')()
  }

  gotoHome () {
    this.goto('/')()
  }

  goto (href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  handleClickFav(data) {
    this.props.cloneFavorite(data)
    this.goto('/parameters/form/general')()
  }

  async handleDelete(favorite) {
    try {
      await indexedDB.deleteFavorite(favorite.name)
      this.setState({
        favorites: this.state.favorites.filter((i) => i.name !== favorite.name)
      })
    } catch (error) {
      console.log('Choices::handleDelete', error)      
    }
  }

  selectDelete () {
    let stateNow = this.state.isDeleteSelected
    this.setState({
      isDeleteSelected : !stateNow
    })
  }

  render() {
    const handleClickFav = this.state.isDeleteSelected ? this.handleDelete : this.handleClickFav
    const favButtons = this.state.favorites.map(fav => <ButtonFavorite data = {fav} key = {fav.name} handleClickFav = {handleClickFav} isDeleteSelected = {this.state.isDeleteSelected}/>)

    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div className = "header-left-and-center pos-align-center">
          <h2>{text.title}</h2>
        </div>
        <div className = "content-full grid-choices">
          <div className = "item-grid-choices--top-left btn btn--raised" onClick={this.gotoNew}>{text.new}</div>
          <div className = "item-grid-choices--top-right btn ">
            <DeleteButton onClick = {this.selectDelete} selected = {this.state.isDeleteSelected} cancel = {this.state.isDeleteSelected}/>
          </div>
          <div className = "item-grid-choices--main-full grid-favorites">
            {favButtons}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Choices)
