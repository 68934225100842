export function dateToString (fulldatee, langage) {
  const fulldate = new Date(fulldatee)
  const month = ("0" + (fulldate.getMonth() + 1)).slice(-2);
  const date = ("0" + (fulldate.getDate())).slice(-2);
  const year = fulldate.getFullYear();
  let dateToShow = "";
  switch (langage) {
    case "en" :
      dateToShow = month+"/"+date+"/"+year;
      break;
    case "fr" :
      dateToShow = date+"/"+month+"/"+year;
      break;
    default:
      dateToShow = date+"/"+month+"/"+year;

  }
  return dateToShow
}

export function dateAndHoursToString (fulldatee, langage) {
  const fulldate = new Date(fulldatee)
  const month = ("0" + (fulldate.getMonth() + 1)).slice(-2)
  const date = ("0" + (fulldate.getDate())).slice(-2)
  const year = fulldate.getFullYear()
  const hours = fulldate.getHours().toString().padStart(2, '0')
  const minutes = fulldate.getMinutes().toString().padStart(2, '0')
  let dateToShow = "";
  switch (langage) {
    case "en" :
      dateToShow = year+"/"+month+"/"+date+ " "+hours+":"+minutes;
      break;
    case "fr" :
      dateToShow = date+"/"+month+"/"+year;
      break;
    default:
      dateToShow = date+"/"+month+"/"+year;

  }
  return dateToShow
}
