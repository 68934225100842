import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
const text = en;
import {Status, RealTimeItem, CockpitButton, ChartButton} from '../../../util/RecordingComponents'


const RealTimeStatus = (props) => {
  const {realTimeData} = props;
  const items= [
    {name: 'distance', unit: 'm'},
    {name: 'speed', unit: 'm/min'},
    {name: 'blowing', unit: 'bar'},
    {name: 'clamping', unit: '%'}
  ];
  const RealTimeItems = items.map((item, index) => {
    return (
      <RealTimeItem
        number={index+1}
        name ={item.name}
        data={realTimeData[item.name]}
        unit={item.unit}
        title={text[item.name]}
        key={item.name}
      />
    )
  });
  return (
    <div className = "content-right grid-sidebar">
      {RealTimeItems}
    </div>
  )
}

const needlePoints = (cx, cy, angle, r) => {
  let nx1 = cx + 2 * Math.cos((angle - 90));
  let ny1 = cy + 2 * Math.sin((angle - 90));

  let nx2 = cx + r * Math.cos(angle);
  let ny2 = cy + r * Math.sin(angle);

  let nx3 = cx + 2 * Math.cos((angle + 90));
  let ny3 = cy + 2 * Math.sin((angle + 90));

  let points = nx1 + "," + ny1 + " " + nx2 + "," + ny2 + " " + nx3 + "," + ny3;
  return points
}

const Gauge = (props) => {
  let {data, fmax} = props;
  const rGauge = 35;
  const circumference = 3*3.14/2*rGauge;
  const circumferenceFull = 2*3.14*rGauge;
  const max = 150;
  const base = circumferenceFull - circumference;
  const offset = circumference - (3.14/4*rGauge);
  let full = data*circumference/max;
  let empty = circumference - full + base;
  const strokeWidth = 8;
  const cx = 40; //x-position of the circles in the viewport
  const cy = 40; //y-position of the circles in the viewport
  const circFmax = (max-fmax)*circumference/max;
  const strokeWidthTick = 2;
  const rTick = rGauge - (strokeWidth/2)- (strokeWidthTick/2);
  const circTick = 3*3.14/2*rTick;
  const circTickFull = 2*3.14*rTick;
  const widthTick = 0.25;
  const tick = 10*circTick/max - widthTick;
  const offsetTick = circTick - (3.14/4*rTick);
  const rScale = rTick - 4;
  const labels = Array.from(Array(16).keys()).map(i => i * 10);
  const rangeAngle = (3*3.14/2)/15;
  const startAngle = 5*3.14/4;
  const arrAngle = labels.map((x, i) => startAngle-(rangeAngle*i));
  const cos = arrAngle.map(angle => Math.cos(angle));
  const texts = arrAngle.map((angle, i) => {
    const x = cx + rScale * Math.cos(angle);
    const y = cy - rScale * Math.sin(angle)+1;
    const text = labels[i]
    return(
      <text x={x} y={y} key={text} textAnchor="middle">{text}</text>
    )
  })

  let formattedNumber = Math.floor(data);

  let angle = full / rGauge - startAngle;
  let rotationAngle = (full / rGauge)*180/Math.PI;
  let points = needlePoints(cx, cy, -startAngle, rGauge)

  const colorData = data > fmax ? "text-danger" : "text-force";
  return (
    <div className = "content-center-234 grid-gauge">
      <svg viewBox="0 0 80 80" className="item-grid-gauge--full gauge">

        <circle id="circle-thicks" cx={cx} cy={cy} r={rTick} fill="transparent"   strokeDasharray={widthTick+" "+tick} strokeDashoffset={offsetTick+(widthTick/2)} strokeWidth = {strokeWidthTick}></circle>

        <circle id="circle-thicks-mask" cx={cx} cy={cy} r={rTick} fill="transparent"   strokeDasharray={(circTickFull - circTick)+" "+circTick} strokeDashoffset={-(circTickFull - circTick)/2} strokeWidth = {strokeWidthTick+1}></circle>

    		<circle  id="circle-base" cx={cx} cy={cy} r={rGauge} fill="transparent"   strokeDasharray={circumference+" "+base} strokeDashoffset={offset} strokeWidth = {strokeWidth}></circle>

        <circle id="circle-data" cx={cx} cy={cy} r={rGauge} fill="transparent"   strokeDasharray={full+" "+empty} strokeDashoffset={offset} strokeWidth = {strokeWidth}></circle>

        <circle id="circle-fmax" cx={cx} cy={cy} r={rGauge} fill="transparent"   strokeDasharray={circFmax+" "+(circumferenceFull-circFmax)} strokeDashoffset={-circumference+circFmax+offset} strokeWidth = {strokeWidth}></circle>

        {texts}

        <polygon className="needle" points={points}       transform={'rotate('+rotationAngle+' '+cx+' '+cy+')'}></polygon>
  	  </svg>

      <div className = "item-grid-gauge--number pos-align-bot pos-justify-center">
        <span className = {"gauge__number pos-justify-center "+colorData}>{formattedNumber}</span>
        <br/>
        <span className = "gauge__units pos-justify-center text-force">Pushing Force [N]</span>
      </div>
    </div>
  )
}

class Cockpit extends React.Component {
  constructor (props) {
    super(props)
    this.gotoChart = this.gotoChart.bind(this)
  }

  componentDidMount(){
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1)
    };
  }

  componentDidUpdate(prevProps, prevState){
    const recordingStatus = this.props.recordingStatus
    if (recordingStatus === "Finish") {
      const href = "/recording/finish"
      this.props.history.push(href);
      this.props.updateLocation(href)
    }
  }
  gotoChart () {
    this.goto("/recording/chart")()
  }
  goto (href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }
  render () {
    const {realTimeData, recordingStatus, isOnline} = this.props
    let fmax = 150
    if (this.props.maxForce) fmax = this.props.maxForce
    else if (this.props.parameters) fmax = this.props.parameters.cable.fmax
    return (
      <div className = "grid-main">
        <Status recordingStatus = {recordingStatus} isOnline = {isOnline}/>
        <Gauge
          data = {realTimeData.force}
          fmax = {fmax}
        />
        <RealTimeStatus
          realTimeData = {realTimeData}
        />
        <ChartButton onClick = {this.gotoChart}/>
        <CockpitButton/>
      </div>
    )
  }
}
export default withRouter(Cockpit);
