import 'typeface-roboto/'
import './index.scss'

import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'

// Routes
import Home from '../routes/home/Home'
import Choices from '../routes/parameters/choices/Choices'
import Report from '../routes/settings/Report'
import Logs from '../routes/settings/Logs'
import Metrics from '../routes/settings/Metrics'
import Form from '../routes/parameters/form/Form'
import Validation from '../routes/parameters/validation/Validation'
import Method from '../routes/parameters/method/Method'
import Save from '../routes/parameters/saveasfavorite/Save'
import Chart from '../routes/recording/chart/Chart'
import MaxForce from '../routes/recording/chart/MaxForce'
import Cockpit from '../routes/recording/cockpit/Cockpit'
import Finish from '../routes/recording/finish/Finish'
import Reports from '../routes/reports/Reports'

// Helpers
import Socket from './socket'
import server from '../util/server'
import defaultState from './default-state'

import indexedDB from '../util/indexedDB'


export default class Shell extends React.Component {

  constructor (props) {
    super(props)
    this.state = defaultState

    this.cloneFavorite = this.cloneFavorite.bind(this)
    this.updateParameter = this.updateParameter.bind(this)
    this.updateSetting = this.updateSetting.bind(this)
    this.updateLocation = this.updateLocation.bind(this)
    this.sendParametersToMachine = this.sendParametersToMachine.bind(this)
    this.setCrashTestMode = this.setCrashTestMode.bind(this)
    this.setName = this.setName.bind(this)
    this.connectionChange = this.connectionChange.bind(this)
    this.initiate = this.initiate.bind(this)
  }

  connectionChange(event) {
    // Offline
    if(event.type === 'offline') {
      if (this.socket) this.socket.close()
      this.setState({isOnline: 'no'})
      return
    }

    // Online
    this.setState({isOnline: 'wifi'})

    if (this.scheduledOnlineCheck !== null) {
      clearInterval(this.scheduledOnlineCheck)
      this.scheduledOnlineCheck = null
    }

    this.scheduledOnlineCheck = setInterval(async () => {
      try {
        const response = await server.getStatus()
        clearInterval(this.scheduledOnlineCheck)
        this.scheduledOnlineCheck = null

        const recordingStatus = response.recordingStatus
        this.setState({isOnline: 'server', recordingStatus})

        this.restoreStateFrom(response)

        this.socket = new Socket()
        this.socket.on('close', this.onSocketClose.bind(this))
        this.socket.on('data', this.onSocketData.bind(this))
      } catch (error) {
        if (error.message === 'Request timed out') return
        if (error.message === 'Failed to fetch') return
        console.error(error)        
      }
    }, 2000)

  }

  async restoreStateFrom(response) {
    if (response.recordingStatus === 'Finish') {
      // Includes /chart, /cockpit and /max-force
      const wasRecording = this.state.currentLocation.includes('recording')
      if (wasRecording) window.location.href = '/'
    } else {
      if (this.state.currentLocation === '/') {
        window.location.href = '/recording/chart'
      } else {
        const reportId = response.currentReportId
        try {
          const report = await server.getReportById(reportId)
          const state = {
            report: report,
            realTimeData: defaultState.realTimeData,
            needCrashTest: report.isCrashTest,
            parameters: report.parameters
          }
          this.setState(state)
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  onSocketData(data) {
    let {recordingStatus, realTimeDatas, averageData} = data
    const usedHeap = this.getUsedHeap()

    const wasAlreadyFinished = this.state.recordingStatus === 'Finish'
    if (wasAlreadyFinished && recordingStatus === 'Finish') {
      this.setState({usedHeap})
      return
    }

    const state = {
      recordingStatus,
      maxForce: this.state.parameters.cable.fmax,
      usedHeap
    }

    if (realTimeDatas.length && recordingStatus === 'Recording') {
      
      const size = realTimeDatas.length

      state.maxForce = realTimeDatas[size -1].maxForce
      state.realTimeData = realTimeDatas[size -1]
      state.isNewMeter = !!averageData

      if (averageData && !this.state.needCrashTest) {
        state.recordedData = this.state.recordedData.concat([averageData])
      }

      if (this.state.needCrashTest) {
        state.recordedData = this.state.recordedData.concat(realTimeDatas)
      }

    }

    this.setState(state)
  }

  onSocketClose() {
    this.connectionChange({type: 'online'})
  }

  getUsedHeap() {
    if (window.performance.memory) {
      const usedHeap = window.performance.memory.usedJSHeapSize / (1024 * 1024)
      return usedHeap.toFixed(0) + 'Mb'
    } else {
      return '-Mb'
    }
  }

  async componentDidMount () {
    let currentLocation = window.location.href.split('/')
    currentLocation.splice(0,3)
    currentLocation  = '/' + currentLocation.join('/')
    const needCrashTest = this.state.parameters.cable.fmaxSource === 'CrashTest'
    this.setState({currentLocation, needCrashTest})

    if (navigator.onLine) this.connectionChange({type: 'online'})
    window.addEventListener('online', this.connectionChange, false)
    window.addEventListener('offline', this.connectionChange, false)

    try {
      const settings = await indexedDB.getSettings()
      if (settings) this.setState({settings})
      else await indexedDB.putSettings(this.state.settings)
    } catch (error) {
      console.error('indexedDB settings', error)
    }
  }

  componentWillUnmount() {
    if (this.scheduledOnlineCheck !== null) {
      clearInterval(this.scheduledOnlineCheck)
      this.scheduledOnlineCheck = null
    }
    if (this.socket) this.socket.close()
  }

  cloneFavorite(parameters) {
    this.setState({parameters})
  }

  updateLocation(url) {
    this.setState({currentLocation: url})
  }

  initiate () {
    this.setState({
      recordingStatus: 'Pause',
      isNewMeter: false,
      report: {},
      recordedData: [],
      reportData: []
    })
  }

  updateParameter(event, category, type) {
    let keyToChange = event.target.name
    let newParameters = this.state.parameters
    let state = {}
    switch (type) {
      case 'text':
        newParameters[category][keyToChange] = event.target.value
        break
      case 'checkbox':
        newParameters[category][keyToChange] = !this.state.parameters[category][keyToChange]
        break
      case 'select':
        newParameters[category][keyToChange] = event.target.value
        if (keyToChange === 'fmaxSource') {
          newParameters.cable.fmax = null
          state.needCrashTest = event.target.value === 'CrashTest'
        }
        break
      default:
        newParameters[category][keyToChange] = event.target.value
    }
    state.parameters = newParameters
    state.hasDifferentParameters = true
    this.setState(state)
  }

  async updateSetting(event, category, type) {
    let keyToChange = event.target.name
    let newSettings = this.state.settings
    switch (type) {
      case 'text':
      case 'image':
      case 'textarea':
        newSettings[category][keyToChange] = event.target.value
        break
      case 'checkbox':
        newSettings[category][keyToChange] = !this.state.newSettings[category][keyToChange]
        break
      default:
        newSettings[category][keyToChange] = event.target.value
    }
    this.setState({settings: newSettings})
    await indexedDB.putSettings(newSettings)
  }

  setCrashTestMode(value) {
    this.setState({needCrashTest: value})
  }

  setName(name) {
    const parameters = Object.assign({}, this.state.parameters)
    parameters.name = name
    this.setState({parameters})
  }

  async sendParametersToMachine(needCrashTest) {
    let {report, parameters} = this.state
    let data = {}
    let url = ''
    if (report.parameters) {
      report.parameters = parameters
      data = {report}
      url = '/updateReports'
    } else {
      data = {parameters}
      url = '/newReport'
    }
    data.isCrashTest = needCrashTest
    try {
      const report = await server.sendParameters(data, url)
      this.setState({report})
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const {parameters, realTimeData, recordingStatus, recordedData, reportData, isOnline, settings} = this.state
    return (
      <BrowserRouter>
        <div className="fullwidth">
          <Route exact
            path=""
            render = { (props) =>
              <Home
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
                initiate = {this.initiate}
              />
            }/>
          <Route exact path="/parameters/choices"
            render = { (props) =>
              <Choices
                isOnline = {isOnline}
                cloneFavorite = {this.cloneFavorite}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/parameters/form/:category"
            render = { (props) =>
              <Form
                isOnline = {isOnline}
                parameters = {parameters}
                updateParameter = {this.updateParameter}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/parameters/validation"
            render = { (props) =>
              <Validation
                parameters = {parameters}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/parameters/method"
            render = { (props) =>
              <Method
                isOnline = {isOnline}
                fmaxSource = {parameters.cable.fmaxSource}
                fmax = {parameters.cable.fmax}
                updateLocation = {this.updateLocation}
                setCrashTestMode = {this.setCrashTestMode}
                sendParametersToMachine = {this.sendParametersToMachine}
              />
            }/>
          <Route exact path="/parameters/save-as-favorite"
            render = { (props) =>
              <Save
                isOnline = {isOnline}
                parameters = {parameters}
                updateLocation = {this.updateLocation}
              />
            }/>
          <Route exact path="/recording/chart"
            render = { (props) =>
              <Chart
                parameters = {parameters}
                recordedData = {recordedData}
                realTimeData = {realTimeData}
                isNewMeter = {this.state.isNewMeter}
                maxForce = {this.state.maxForce}
                recordingStatus = {recordingStatus}
                needCrashTest = {this.state.needCrashTest}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
                usedHeap = {this.state.usedHeap}
              />
            }/>
          <Route exact path="/recording/cockpit"
            render = { (props) =>
              <Cockpit
                parameters = {parameters}
                realTimeData = {realTimeData}
                maxForce = {this.state.maxForce}
                recordingStatus = {recordingStatus}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
              />
            }/>
          <Route exact path="/recording/finish"
            render = { (props) =>
              <Finish
                isOnline = {isOnline}
                parameters = {parameters}
                favorite = {this.state.favorite}
                recordedData = {this.state.recordedData}
                updateLocation = {this.updateLocation}
                updateParameter = {this.updateParameter}
                sendParametersToMachine = {this.sendParametersToMachine}
                needCrashTest = {this.state.needCrashTest}
              />
            }/>
          <Route exact path="/recording/max-force"
            render = { (props) =>
              <MaxForce
                updateLocation = {this.updateLocation}
                reportId = {this.state.report.reportId}
                updateParameter = {this.updateParameter}
                initiate = {this.initiate}
                fmax = {this.state.parameters.cable.fmax}
              />
            }/>
          <Route exact path="/reports"
            render = { (props) =>
              <Reports
                settings = {settings}
                updateLocation = {this.updateLocation}
                isOnline = {isOnline}
                usedHeap = {this.state.usedHeap}
              />
            }/>
          <Route exact path="/settings/report"
          render = { (props) =>
            <Report
              isOnline = {isOnline}
              settings = {settings}
              updateLocation = {this.updateLocation}
              updateSetting = {this.updateSetting}
            />
          }/>
          <Route exact path="/settings/logs"
          render = { (props) =>
            <Logs
              isOnline = {isOnline}
              updateLocation = {this.updateLocation}
            />
          }/>
          <Route exact path="/settings/metrics"
          render = { (props) =>
            <Metrics
              isOnline = {isOnline}
              updateLocation = {this.updateLocation}
              usedHeap = {this.state.usedHeap}
            />
          }/>
        </div>
      </BrowserRouter>
    )
  }
}
