import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import server from '../../util/server'
import indexedDB from '../../util/indexedDB'
import {PdfButton} from './PdfButton.js'
import {ButtonHome} from '../../util/ButtonHome'
import {DeleteButton} from '../../util/DeleteButton.js'
import IsOnline from '../../util/IsOnline'

const text = en

const ReportLine = (props) => {
  const {reportId, reportDate, project, operator, client, section, isCrashTest} = props.report
  const isDeleteSelected = props.isDeleteSelected
  const classToAdd = isDeleteSelected ? 'warning' : ''
  const readableDate = reportDate.slice(0, -1).replace('T', ' ').split('.')[0]
  return (
    <div
      className ={ "report-line grid-report-line " + classToAdd}
      onClick =  {() => props.handleClick(reportId)}>
      <h3 className = "item-grid-report-line-tl pos-align-center">
        {project}
      </h3>
      <p className = "item-grid-report-line-tr  pos-align-center pos-justify-right">
        {isCrashTest ? 'Crash Test' : '-'}
      </p>
      <p className = "item-grid-report-line-ml pos-align-center light-color">
        {section}
      </p>
      <p className = "item-grid-report-line-bl pos-align-center light-color">
        {client}
      </p>
      <p className = "item-grid-report-line-mr pos-align-center pos-justify-right light-color">
        {readableDate}
      </p>
      <p className = "item-grid-report-line-br pos-align-center pos-justify-right light-color">
        {operator}
      </p>
    </div>
  )
}

const ReportLineSelected = (props) => {
  const {reportId, project, client, section} = props.report
  const settings = props.settings
  return (
    <div
      className = "report-line grid-report-line report-line--selected">
      <h3 className = "item-grid-report-line-tl pos-align-center">
        {project}
      </h3>
      <p className = "item-grid-report-line-ml pos-align-center light-color">
        {section}
      </p>
      <p className = "item-grid-report-line-bl pos-align-center light-color">
        {client}
      </p>
      <div className = "item-grid-report-line-mm btn btn--veryflat">
        {text.seeReport}
      </div>
      <PdfButton
        width = {600}
        height = {300}
        reportId = {reportId}
        settings = {settings}
        reset = {props.reset}
        />
    </div>
  )
}

class Reports extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      reportsFromIndexedDB: [],
      selected: '',
      reportSelected: {},
      isDeleteSelected: false,
      loaded: false,
      loadingOrigin: 'disk'
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.gotoHome = this.gotoHome.bind(this)
    this.selectDelete = this.selectDelete.bind(this)
    this.reset = this.reset.bind(this)
  }

  componentDidMount() {
    this.start = new Date()
    this.getReports()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOnline === 'wifi' && this.isConnected()) {
      this.getReports()
    }
  }

  isConnected() {
    return this.props.isOnline === 'server'
  }

  async getReports() {
    try {
      let reports = []
      if (this.isConnected()) {
        await indexedDB.deleteReports()
        await indexedDB.deleteFullReports()
        reports = await server.getReports()
        const fullReports = await server.getFullReports(reports)
        await indexedDB.putReports(reports)
        await indexedDB.putFullReports(fullReports)
      } else {
        reports = await indexedDB.getReports()
      }
      this.updateState(reports)
    } catch (error) {
      console.error('[ERROR] Reports.js::getReports', error)
    }
  }

  updateState(reports) {
    this.setState({
      loaded: true,
      loadingTime: (new Date()) - this.start,
      loadingOrigin: this.isConnected() ? 'machine' : 'disk',
      reports
    })
  }

  selectDelete() {
    let stateNow = this.state.isDeleteSelected
    this.setState({
      isDeleteSelected: !stateNow,
      selected: ''
    })
  }

  handleSelect(reportId) {
    this.setState({selected: reportId})
  }

  reset() {
    this.setState({selected: ''})
  }

  async handleDelete(id) {
    if (id === undefined) return
    if (this.props.isOnline !== 'server') return

    try {
      await server.deleteReportById(id)
      await indexedDB.deleteReportById(id)
      await indexedDB.deleteFullReportById(id)
      const newReports = this.state.reports.filter((r) => r.reportId !== id)
      this.setState({reports: newReports})
      if (newReports.length === 0) this.setState({isDeleteSelected: false})
    } catch (error) {
      console.error('Cannot delete from the server', error)
    }

  }

  gotoHome() {
    this.goto('/')()
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  render() {
    const {reports, isDeleteSelected} = this.state
    const settings = this.props.settings

    reports.sort(function(a, b) {
      a = new Date(a.reportDate)
      b = new Date(b.reportDate)
      return a > b ? -1 : a < b ? 1 : 0
    })

    const handleClick = this.state.isDeleteSelected ? this.handleDelete : this.handleSelect

    const ReportLines = reports.map(report => {
      if (this.state.selected === report.reportId) {
        return (
          <ReportLineSelected
            report = {report}
            key = {report.reportId}
            handleSelect = {this.handleSelect}
            settings = {settings}
            reset = {this.reset}
          />
        )
      } else {
        return (
          <ReportLine
            report = {report}
            key = {report.reportId}
            handleClick = {handleClick}
            isDeleteSelected = {isDeleteSelected}
          />
        )
      }
    })

    const fetching = (
      <h2>loading...</h2>
    )


    const loaded = () => {
      let classes = 'bold'
      const loadingOrigin = this.state.loadingOrigin
      if (loadingOrigin === 'disk') classes += ' warning-color'
      else classes += ' success-color'
      return (
        <div className="lighter-color text-small">
          <div>
            loaded from the <span className={classes}>{loadingOrigin}</span>
          </div>
          <div>
            in {this.state.loadingTime}ms
          </div>
        </div>
      )
    }

    const usedHeap = (
      <div className="lighter-color text-xsmall">
        Memory usage: {this.props.usedHeap}
      </div>
    )

    const deleteElement = () => {
      return (
        <div className = "content-right pos-align-bot btn--delete">
          <DeleteButton onClick = {this.selectDelete} selected = {this.state.isDeleteSelected} cancel = {this.state.isDeleteSelected}/>
        </div>
      )
    }

    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>


        <div className = "content-left grid-sidebar">

          <div className = {"item-grid-sidebar-1"}>
            <h2 className = "secondary-color">{text.title}</h2>
            {this.state.loaded ? loaded() : ''}
          </div>
          <div className = {"item-grid-sidebar-3"}>
            {usedHeap}
          </div>


        </div>


        {this.isConnected() ? deleteElement() : ''}
        <div className = "content-center content--scrollable">
          {this.state.loaded ? ReportLines : fetching}
        </div>
      </div>
    )
  }
}

export default withRouter(Reports)
