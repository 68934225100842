import EventEmitter from 'events'
const getMachineOrigin = require('../util/get-machine-origin')
const machineIP = getMachineOrigin().split('/')[2]


module.exports = class Socket extends EventEmitter{

  constructor() {
    super()
    this.webSocket = new WebSocket(`wss://${machineIP}`)
    this.onOpen()
    this.onClose()
    this.onMessage()
  }

  onClose() {
    this.webSocket.onclose = (error) => {
      console.log('[APP] (info) closing the websocket', error)
      this.emit('close')
    }
  }

  onOpen() {
    this.webSocket.onopen = () => {
      const date = getFormattedDate()
      console.log(`[APP] (info) opening the websocket, sending date: ${date}`)
      this.webSocket.send(date)
    }
  }

  onMessage() {
    this.webSocket.onmessage = (message) => {
      const data = parseJSON(message.data)
      if (data === null) return
      this.emit('data', data)
    }
  }

  close() {
    this.webSocket.close()
  }

}


function parseJSON(data) {
  let parsedData = null
  try {
    parsedData = JSON.parse(data)
  } catch (error) {
    console.error(error)
  }
  return parsedData
}


function getFormattedDate() {
  const now = new Date()
  
  const year = now.getFullYear()
  const month = (now.getMonth() + 1).toString().padStart(2, '0')
  const date = now.getDate().toString().padStart(2, '0')
  
  const hours = now.getHours().toString().padStart(2, '0')
  const minutes = now.getMinutes().toString().padStart(2, '0')
  const seconds = now.getSeconds().toString().padStart(2, '0')
  
  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
}
