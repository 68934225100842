import {openDb} from 'idb'

const reportsStore = 'reports-store'
const dbPromise = openDb('reportsDataBase', 1, (upgradeDb) => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(reportsStore)
    }
  })

const fullReportsStore = 'fullReports-store'
const db2Promise = openDb('fullReportsDataBase', 1, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(fullReportsStore)
  }
})

const settingsStore = 'settings-store'
const db3Promise = openDb('settingsDataBase', 1, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(settingsStore)
  }
})

const favoriteStore = 'favorite-store'
const db4Promise = openDb('favoriteDB', 1, upgradeDb => {
  switch (upgradeDb.oldVersion) {
    case 0:
      upgradeDb.createObjectStore(favoriteStore)
  }
})


module.exports = {
  getSettings,
  putSettings,
  getReports,
  getFullReportById,
  deleteReports,
  deleteFullReports,
  deleteReportById,
  deleteFullReportById,
  putReports,
  putFullReports,
  getFavorites,
  deleteFavorite,
  saveFavorite
}

async function getSettings() {
  const db = await db3Promise
  return db.transaction(settingsStore).objectStore(settingsStore).get(0)
}

async function putSettings(settings) {
  try {
    const db = await db3Promise
    const tx = db.transaction(settingsStore, 'readwrite')
    await tx.objectStore(settingsStore).put(settings, 0)
  } catch (error) {
    throw error
  }
}

async function getReports() {
  const db = await dbPromise
  return db.transaction(reportsStore).objectStore(reportsStore).getAll()
}

async function getFullReportById(id) {
  const db = await db2Promise
  return db.transaction(fullReportsStore)
    .objectStore(fullReportsStore).get(id)
}

async function deleteReports() {
  try {
    const db = await dbPromise
    const tx = db.transaction(reportsStore, 'readwrite')
    const reportIds = await tx.objectStore(reportsStore).getAllKeys()
  
    reportIds.forEach(async (id) => {
      await tx.objectStore(reportsStore).delete(id)
    })
  } catch (error) {
    throw error
  }
}

async function deleteFullReports() {
  try {
    const db = await db2Promise
    const tx = db.transaction(fullReportsStore, 'readwrite')
    const reportIds = await tx.objectStore(fullReportsStore).getAllKeys()
  
    reportIds.forEach(async (id) => {
      await tx.objectStore(fullReportsStore).delete(id)
    })
  } catch (error) {
    throw error
  }
}

async function deleteReportById(id) {
  try {
    const db = await dbPromise
    const tx = db.transaction(reportsStore, 'readwrite')
    await tx.objectStore(reportsStore).delete(id)
  } catch (error) {
    throw error
  }
}

async function deleteFullReportById(id) {
  try {
    const db2 = await db2Promise
    const tx2 = db2.transaction(fullReportsStore, 'readwrite')
    await tx2.objectStore(fullReportsStore).delete(id)
  } catch (error) {
    throw error
  }
}

async function putReports(reports) {
  try {
    const db = await dbPromise
    const tx = db.transaction(reportsStore, 'readwrite')

    reports.forEach(async (report) => {
      await tx.objectStore(reportsStore).put(report, report.reportId)
    })
  } catch (error) {
    throw error
  }
}

async function putFullReports(fullReports) {
  try {
    const db = await db2Promise
    const tx = db.transaction(fullReportsStore, 'readwrite')

    fullReports.forEach(async (fullReport) => {
      await tx.objectStore(fullReportsStore)
        .put(fullReport, fullReport.reportId)
    })
  } catch (error) {
    throw error
  }
}

async function getFavorites() {
  const db = await db4Promise
  return db.transaction(favoriteStore).objectStore(favoriteStore).getAll()
}

async function deleteFavorite(key) {
  const db = await db4Promise
  const tx = db.transaction('favorite-store', 'readwrite')
  tx.objectStore('favorite-store').delete(key)
  return tx.complete
}

async function saveFavorite(favorite) {
  const db = await db4Promise
  const tx = db.transaction('favorite-store', 'readwrite')
  tx.objectStore('favorite-store').put(favorite, favorite.name)
  return tx.complete
}
