module.exports = {
  getSourceAsDataURL,
  loadImage,
  getResizedCanvasFromImage,
  getFileFromCanvas
}

function getSourceAsDataURL(file) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.onerror = (error) => reject(error)
  })
}

function loadImage(source) {
  const image = new Image()
  image.src = source
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = source
    image.onload = () => resolve(image)
    image.onerror = (error) => reject(error)
  })
}

function getResizedCanvasFromImage(image, {height}) {
  const width = height * image.width / image.height
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const context = canvas.getContext('2d')
  context.drawImage(image, 0, 0, width, height)
  return context.canvas
}

function getFileFromCanvas(canvas, filename) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const file = new File([blob], filename, {
        type: 'image/jpeg',
        lastModified: Date.now()
      })
      resolve(file)
    }, 'image/jpeg', 1)
  })
}
