import './recording-component.scss'

import React from 'react'

const RealTimeItem = (props) => {
  const {number, unit, title, name, data} = props;
  return (
    <div className = {"item-grid-sidebar-"+number}>
      <div className = {"text-"+name}>{title}<span className = "light"> ({unit})</span></div>
      <div className = {"big-number text-"+name}>{data}</div>
    </div>
  )
}

const Status = (props) => {
  const {recordingStatus, isOnline, needCrashTest} = props
  let text = ""
  let classAdded = ""
  if (isOnline === "server") {
    text = recordingStatus
    if (needCrashTest) text = 'Crash Test'
    classAdded = recordingStatus
  } else {
    text = "Check Wifi"
    classAdded = "NoConnection"
  }

  return (
    <div className = "header-left pos-align-center grid-status">
      <div className = {"item-grid-status--left pos-align-center indicator  "+classAdded}></div>
      <h3 className = {"item-grid-status--right pos-align-center secondary-color uppercase "+classAdded}>{text}</h3>
    </div>
  )
}

const CockpitButton = (props) => {
  const color = props.onClick ? "#CCCCCC" :"#002169"
  const classToAdd = props.onClick ? "enable" : "disable"
  return (
    <div className = {"btn btn--veryflat grid-btn-home header-4 pos-align-center "+classToAdd} onClick = {props.onClick}>
      <div className = "item-grid-btn-home-left pos-justify-right">
        <CockpitIcon/>
      </div>
      <div className = "item-grid-btn-home-right pos-justify-left">
        Cockpit
      </div>
    </div>
  )
}

const ChartButton = (props) => {
  const color = props.onClick ? "#CCCCCC" : "#002169"
  const classToAdd = props.onClick ? "enable" : "disable"
  return (
    <div className = {"btn btn--veryflat grid-btn-home header-3 pos-align-center "+classToAdd} onClick = {props.onClick}>
      <div className = "item-grid-btn-home-left pos-justify-right">
        <ChartIcon/>
      </div>
      <div className = "item-grid-btn-home-right pos-justify-left">
        Chart
      </div>
    </div>
  )
}

export {
  Status,
  RealTimeItem,
  CockpitButton,
  ChartButton
}


const ChartIcon = (props) => {
  return (
    <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path className="svg__primary-color" d="M3,18 L1,18 C3.66666667,8 5.66666667,3 7,3 C9,3 13,11 14,11 C14.6666667,11 15.3333333,9.66666667 16,7 L18,7 C16.6666667,11 15.3333333,13 14,13 C12,13 8,5 7,5 C6.33333333,5 5,9.33333333 3,18 Z"></path>
      <polygon className="svg__primary-color" points="0 0 2 0 2 16 18 16 18 18 0 18"></polygon>
    </svg>
  )
}

const CockpitIcon = (props) => {

  return (
    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path className="svg__secondary-color" d="M9,9 C8.33333333,9.66666667 8.33333333,10.3333333 9,11 C9.66666667,11.6666667 10.3333333,11.6666667 11,11 L15,5 L9,9 Z" ></path>
      <path className="svg__light-color" d="M2.92893219,17.0710678 C2.91634542,17.058481 2.90379205,17.0458609 2.89127223,17.0332075 C1.10377212,15.2266342 0,12.742217 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,12.7503346 18.8896833,15.2413409 17.0928355,17.0492333 C17.0855908,17.0565226 17.0783349,17.0638008 17.0710678,17.0710678 L15.6568543,15.6568543 C15.6626679,15.6510406 15.6684726,15.6452181 15.6742684,15.6393866 C17.1117467,14.1930727 18,12.2002677 18,10 C18,5.581722 14.418278,2 10,2 C5.581722,2 2,5.581722 2,10 C2,12.1937736 2.8830177,14.1813074 4.31301778,15.626566 C4.32303364,15.6366887 4.33307634,15.6467848 4.34314575,15.6568542 L2.92893219,17.0710678 Z"></path>
      <path className="svg__primary-color" d="M2.92893219,17.0710678 C2.91634542,17.058481 2.90379205,17.0458609 2.89127223,17.0332075 C1.10377212,15.2266342 0,12.742217 0,10 C0,4.4771525 4.4771525,0 10,0 C12.7614237,0 15.2614237,1.11928813 17.0710678,2.92893219 L15.6568542,4.34314575 C14.209139,2.8954305 12.209139,2 10,2 C5.581722,2 2,5.581722 2,10 C2,12.1937736 2.8830177,14.1813074 4.31301778,15.626566 C4.32303364,15.6366887 4.33307634,15.6467848 4.34314575,15.6568542 L2.92893219,17.0710678 Z"></path>
    </svg>
  )
}
