import './style.scss'

import React from 'react'
import {ButtonHome} from '../../util/ButtonHome'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import Input from '../../util/Input'
import settingsTextEn from '../../texts/settings-en.json'
import settingsSource from './settings.json'
import IsOnline from '../../util/IsOnline'
import Menu from './Menu'

import {
  getSourceAsDataURL,
  loadImage,
  getResizedCanvasFromImage,
  getFileFromCanvas
} from './file-helper'

const text = en
const settingsText = settingsTextEn


class Report extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      categoryIndex: 0,
    }
    this.gotoHome = this.gotoHome.bind(this)

    this.curriculum = React.createRef()
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  gotoHome() {
    this.goto('/')()
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  async handleSubmit() {
    const logo = this.curriculum.current.files[0]

    try {
      const source = await getSourceAsDataURL(logo)
      const image = await loadImage(source)
      const canvas = getResizedCanvasFromImage(image, {height: 100})
      const resizedLogo = await getFileFromCanvas(canvas, logo.name)
      const resizedSource = await getSourceAsDataURL(resizedLogo)

      this.setState({source: resizedSource})
      const event = {target: {name: 'logo',value: resizedSource}}
      this.props.updateSetting(event, 'client', 'image')
    } catch (error) {
      console.error(error)      
    }

    event.preventDefault()
  }

  render() {
    const sections = ['client']

    const image = () => {
      if (!this.props.settings.client.logo) return ''
      return (
        <div className="">
          <img
            src={this.props.settings.client.logo}
            height="80px"/>
        </div>
      )
    }

    const upload = (
      <div className="">
        <label
          htmlFor="files"
          className="btn btn--raised logo-label">
          New Logo
        </label>
        <input
          id="files"
          type="file"
          ref={this.curriculum}
          onChange={this.handleSubmit}/>
      </div>
    )

    const Sections = sections.map((item) => {

      const Inputs = settingsSource[item].map((input) => {
        return (
          <Input
            inputdata = {input}
            label = {settingsText[item][input.name]}
            value = {this.props.settings[item][input.name]}
            handleChange = {((e) => this.props.updateSetting(e, item, input.type))}
            key = {input.name}
          />
        )
      })

      return (
        <div key = {item}>
          {/* <h4>{settingsText[item].title}</h4> */}
          <br/>
          <div className="logo-container">
            {upload}
            {image()}
          </div>
          <br/>
          {Inputs}
        </div>
      )
    })

    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div
          className = "header-left-and-center pos-align-bot">
          <h2 className = "secondary-color">
            Settings
            </h2>
        </div>
        <Menu
          categoryIndex={this.state.categoryIndex}
          goto={this.goto.bind(this)}/>
        <div className = "content-center content--scrollable">
          {Sections}
        </div>
      </div>
    )
  }

}


export default withRouter(Report)
