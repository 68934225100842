import React from 'react'

export const ButtonHome = (props) => {
  return (
    <div className = "btn btn--veryflat grid-btn-home btn--home" onClick = {props.onClick}>
      <div className = "item-grid-btn-home-left pos-justify-right">
        <HomeIcon color = "#002169"/>
      </div>
      <div className = "item-grid-btn-home-right pos-justify-left">
        Home
      </div>
    </div>
  )
}

const HomeIcon = (props) => {
  const color = props.color;
  return (
    <svg viewBox="0 0 20 18" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <defs></defs>
      <g id="HOME-/-selected" transform="translate(-2.000000, -2.000000)" fill={color}>
        <path d="M4,8 L2,8 L12,2 L16.0559048,4.43354288 L17,5 L17,4 C17,3.44771525 17.4477153,3 18,3 L18.0060816,3 C18.5559856,3 19.0027085,3.44400612 19.006063,3.99389989 L19.0195924,6.21175546 L22,8 L20,8 L20,18 C20,19.1045695 19.1045695,20 18,20 L16,20 L16,15 L12,15 L12,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,8 Z" id="Rectangle-2"></path>
      </g>
    </svg>
  )
}
