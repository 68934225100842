import './style.scss'

import React from 'react'
import {ButtonHome} from '../../util/ButtonHome'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import IsOnline from '../../util/IsOnline'
import Menu from './Menu'

import server from '../../util/server'

const text = en


class Metrics extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      categoryIndex: 1,
      metrics: null,
      loaded: false,
      user: null,
      error: null,
      usedBytes: null,
      grantedBytes: null,
      hardwareConcurrency: null
    }
    this.gotoHome = this.gotoHome.bind(this)
  }

  gotoHome() {
    this.goto('/')()
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  async componentDidMount() {
    if ('webkitTemporaryStorage' in navigator) {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        (usedBytes, grantedBytes) => {
          this.setState({usedBytes, grantedBytes})
        },
        (error) => {
          console.log(error)
        }
      )
    }

    if ('hardwareConcurrency' in navigator) {
      this.setState({
        hardwareConcurrency: navigator.hardwareConcurrency
      })
    }

    if (this.props.isOnline !== 'server') return
    server.getMetrics()
      .then((metrics) => this.setState({metrics, loaded: true}))
      .catch((error) => {
        console.error('Metrics::componentDidMount', error)
        this.setState({error, loaded: false})
      })
  }

  render() {
    const {usedBytes, grantedBytes} = this.state
    let storage = ''
    if (usedBytes !== null && grantedBytes !== null) {
      const readableUsedBytes = (usedBytes / 1024 / 1024).toFixed(1)
      const readableGrantedBytes = (grantedBytes / 1024 / 1024).toFixed(0)
      storage = (
        <div>
          Disk        {readableUsedBytes}M used of {readableGrantedBytes}M
        </div>
      )
    }

    let cores = ''
    if (this.state.hardwareConcurrency)  {
      cores = <div>Cores       {this.state.hardwareConcurrency}</div>
    }

    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div
          className = "header-left-and-center pos-align-bot">
          <h2 className = "secondary-color">
            Settings
            </h2>
        </div>
        <Menu
          categoryIndex={this.state.categoryIndex}
          goto={this.goto.bind(this)}/>
        <div className = "content-center content--scrollable">
          <div className="monospace">
            <h3 className="metrics-title">Tablet</h3>
            {storage}
            <div>Memory      {this.props.usedHeap}</div>
            {cores}
            <div>Mode        {process.env.NODE_ENV}</div>
            <br/>
            <Dashboard
              metrics = {this.state.metrics}
              error = {this.state.error}/>
          </div>
        </div>
      </div>
    )
  }

}


export default withRouter(Metrics)


const Dashboard = (props) => {
  const {metrics, error} = props

  if (error) return <h4>Error: {error.message}</h4>
  if (!metrics) return ''

  const networkInterfaces = metrics.os.networkInterfaces

  const netInterfaces = []

  for (const key in networkInterfaces) {
    if (networkInterfaces.hasOwnProperty(key)) {
      const data = networkInterfaces[key]
        .filter(elt => {return elt.internal === false && elt.family === 'IPv4'})
        .map(elt => {return {address: elt.address, mac: elt.mac}})
      if (data.length) {
        netInterfaces.push({
          name: key,
          data: networkInterfaces[key]
            .filter(elt => {return elt.internal === false && elt.family === 'IPv4'})
            .map(elt => {return {address: elt.address, mac: elt.mac}})
        })
      }
    }
  }

  return (
    <div>
      <h3 className="metrics-title">Machine</h3>
      <h4>Health</h4>
      <div>
        <span>Disk        </span>
        <span>{metrics.health.disk.usage}</span>
        <span> used of </span>
        <span>{metrics.health.disk.size}</span>
      </div>
      <div>
        <span>Memory      </span>
        <span>{metrics.health.memory.usage}</span>
        <span> used of </span>
        <span>{metrics.health.memory.size}</span>
      </div>
      <div>
        <span>Cores       </span>
        <span>{metrics.health.cpu.quantity}</span>
      </div>
      <div>
        <span>CPU loads   </span>
        <span>{metrics.health.cpu.loads.join(', ')}</span>
      </div>

      <h4>OS</h4>
      <div>Hostname: {metrics.os.hostname}</div>
      <div>
        <div>Network Interfaces:</div>
        <div>{buildNetworkInterfacesUI(netInterfaces)}</div>
      </div>

      <h4>Process</h4>
      <div>
        <div>Env:</div>
        <div>{buildEnvUI(metrics.process.env)}</div>
      </div>
      <div>PID: {metrics.process.pid}</div>
      <div>Version: {metrics.process.version}</div>
    </div>
  )
}

function buildNetworkInterfacesUI(netInterfaces) {
  const spans = []
  netInterfaces.forEach((elt, id) => {
    const key = `net-${id}`
    elt.data.forEach(e => {
      spans.push(<div key={key}>&nbsp;&nbsp; [{elt.name}] {e.address}</div>)
    })
  })
  return spans
}

function buildEnvUI(env) {
  const spans = []

  for (const variable in env) {
    if (env.hasOwnProperty(variable)) {
      if (isWhiteListed(variable)) {
        const key = `env-${variable}`
        spans.push(<div key={key}>&nbsp;&nbsp; {variable}: {env[variable]}</div>)
      }
    }
  }

  return spans
}

function buildVersionsUI(versions) {
  const spans = []

  for (const version in versions) {
    if (versions.hasOwnProperty(version)) {
      const key = `versions-${version}`
      spans.push(<div key={key}>&nbsp;&nbsp; {version}: {versions[version]}</div>)
    }
  }

  return spans
}

function isWhiteListed(variable) {
  const strictWhiteList = [
    // 'LANG',
    // 'LANGUAGE',
    // 'npm_config_node_version',
    'npm_config_user_agent',
    'npm_package_version',
    'BUMBLEBEE_MACHINE_ORIGIN',
    'BUMBLEBEE_TABLET_ORIGIN',
    'NODE_ENV'
  ]

  const partialWhiteList = [
    // 'npm_package_devDependencies',
    'npm_package_dependencies'
  ]

  const isInPartialWhiteList = partialWhiteList.some(whiteListItem => {
    return variable.startsWith(whiteListItem)
  })

  return strictWhiteList.includes(variable) || isInPartialWhiteList
}

