import React from 'react'

export const DoneIcon = (props) => {
  return (
    <svg viewBox="0 0 71 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="button-done" transform="translate(-124.000000, -31.000000)" fill="#002169" fillRule="nonzero">
          <path d="M193.12849,69.0837345 L131.437905,31.0184816 C129.985394,30.1222317 127.655896,30.1222317 126.203386,31.0184816 C124.750875,31.9147314 124.750875,33.3521133 126.203386,34.2483631 L185.263009,70.6902201 L155.203386,97.1320771 C153.750875,98.0283269 153.750875,99.4657087 155.203386,100.361959 C155.915938,100.801628 156.875143,101.038374 157.806942,101.038374 C158.738741,101.038374 159.697947,100.818539 160.410499,100.361959 L193.101085,72.2967057 C194.553595,71.4173662 194.553595,69.963074 193.12849,69.0837345 Z"  transform="translate(159.653960, 65.692334) rotate(90.000000) translate(-159.653960, -65.692334) "></path>
      </g>
    </svg>
  )
}
