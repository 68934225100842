import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import Graphs from './Graphs'
import {Status, RealTimeItem, CockpitButton, ChartButton} from '../../../util/RecordingComponents'

const text = en

const RealTimeItemSmall = (props) => {
  const {position, unit, title, data} = props
  return (
    <div className = {"item-grid-smaller-realtimedata-"+position}>
      <div className = "text-small">{title}</div>
      <div>{data} <span className = "light text-small"> {unit} </span></div>
    </div>
  )
}

const RealTimeStatus = (props) => {
  const {fmax, realTimeData, size, usedHeap} = props
  const items= [
    {name: 'distance', unit: 'm'},
    {name: 'force', unit: 'N'},
    {name: 'speed', unit: 'm/s'},
    {name: 'blowing', unit: 'bar'}
  ]
  const RealTimeItems = items.map((item, index) => {
    return (
      <RealTimeItem
        number={index+1}
        name ={item.name}
        data={realTimeData[item.name]}
        unit={item.unit}
        title={text[item.name]}
        key={item.name}
      />
    )
  })

  return (
    <div className = "content-left grid-sidebar">
      {RealTimeItems}
      <div className = "item-grid-sidebar-5 grid-smaller-realtimedata">
        <RealTimeItemSmall
          position = "t"
          title = {text.fmax}
          unit = "N"
          data={fmax}
        />
        <RealTimeItemSmall
          position = "bl"
          title = {text.temperature}
          unit = "°C"
          data={realTimeData["temperature"]}
        />
        <RealTimeItemSmall
          position = "br"
          title = {text.duration}
          unit = "min"
          data={realTimeData["duration"]}
        />
      </div>
      <div className="lighter-color text-xsmall">{`${size} pts`}</div>
      <div className="lighter-color text-xsmall">{`${usedHeap} ${process.env.NODE_ENV}`}</div>
    </div>
  )
}

class Chart extends React.Component {
  constructor (props) {
    super(props)
    this.gotoCockpit = this.gotoCockpit.bind(this)
  }

  componentDidMount(){
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
        history.go(1)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {recordingStatus} = this.props
    const isFinished = recordingStatus === 'Finish'
    const wasRecording = prevProps.recordingStatus === 'Recording'
    if (isFinished && wasRecording) {
      let href = '/recording/finish'
      if (this.props.needCrashTest) href = '/recording/max-force'
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  gotoCockpit () {
    this.goto('/recording/cockpit')()
  }

  goto (href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  render () {
    const {realTimeData, recordingStatus, recordedData, isOnline} = this.props
    let fmax = 150
    if (this.props.maxForce) fmax = this.props.maxForce
    else if (this.props.parameters) fmax = this.props.parameters.cable.fmax
    return (
      <div className = "grid-main">
        <Status
          recordingStatus = {recordingStatus}
          isOnline = {isOnline}
          needCrashTest = {this.props.needCrashTest}/>
        <RealTimeStatus
          realTimeData = {realTimeData}
          fmax = {fmax}
          size = {recordedData.length}
          usedHeap = {this.props.usedHeap}
        />
        <Graphs
          recordedData = {recordedData}
          width = {780}
          height = {460}
          isNewMeter = {this.props.isNewMeter}
          needCrashTest = {this.props.needCrashTest}/>
        <ChartButton/>
        <CockpitButton onClick = {this.gotoCockpit}/>
      </div>
    )
  }
}


export default withRouter(Chart)
