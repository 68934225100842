import './style.scss'

import React from 'react'
import en from './en.json'
const text = en
// TODO text[measure]

const Chart = require('chart.js')


export default class Graphs extends React.Component {
  constructor (props) {
    super(props)
    this.previousLength = 0
    this.maxDistance = 0
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.needCrashTest && this.props.needCrashTest !== nextProps.needCrashTest) {
      this.myChart.options.scales.xAxes[0].type = 'time'
      this.myChart.options.scales.xAxes[0].time = {
        unit: 'second',
        displayFormats: {
          second: 'HH:mm:ss'
        }
      }
      this.myChart.update()
    }

    // Do not update if a new meter is not reached
    if (!this.props.isNewMeter && !this.props.needCrashTest) return false

    const numberOfPoints = this.props.recordedData.length
    const lastIndex = numberOfPoints - 1
    if (lastIndex === -1) return false
    const speed = this.props.recordedData[lastIndex].speed
    // If lots of points and speedy only update for odd number of points
    if (numberOfPoints > 400 && speed > 40 && numberOfPoints % 2 === 0) {
      return false
    } else {
      return true
    }
  }

  componentDidUpdate() {
    if (this.props.maxForce !== undefined) {
      this.drawMaxForce()
      this.myChart.update()
    }

    const data = this.props.recordedData
    if (!data.length || this.previousLength === data.length) return
    

    for (let index = data.length - this.previousLength; index > 0; index--) {
      const distance = data[data.length - index].distance
      const force = data[data.length - index].force
      const speed = data[data.length - index].speed
      const blowing = data[data.length - index].blowing
      const stamp = data[data.length - index].stamp
      
      this.update(this.myChart.data, distance, force, speed, blowing, stamp)
    }

    this.previousLength = data.length

    // const distance = data[data.length - 1].distance
    // const force = data[data.length - 1].force
    // const speed = data[data.length - 1].speed
    // const blowing = data[data.length - 1].blowing
    // const stamp = data[data.length - 1].stamp

    // this.update(this.myChart.data, distance, force, speed, blowing, stamp)

    this.myChart.update()
  }

  componentDidMount() {
    const canvas = document.getElementById('myChart')

    const data = {
      labels: [],
      datasets: [
        {
          label: 'Force',
          yAxisID: 'y-axis-force',
          borderColor: '#F88181',
          borderWidth: 3,
          fill: false,
          data: []
        },
        {
          label: 'Speed',
          yAxisID: 'y-axis-speed',
          borderColor: '#7AC9A8',
          borderWidth: 3,
          fill: false,
          data: []
        },
        {
          label: 'Blowing',
          yAxisID: 'y-axis-blowing',
          borderColor: '#6AA6E6',
          borderWidth: 3,
          fill: false,
          data: []
        },
        {
          label: 'Max Force',
          yAxisID: 'y-axis-max-force',
          borderColor: '#F88181',
          borderWidth: 3,
          borderDash: [10],
          fill: false,
          data: []
        },
      ]
    }

    const recordedData = this.props.recordedData

    this.max = 0
    recordedData.forEach((e) => {
      if (e.force > this.max) this.max = e.force
      this.update(data, e.distance, e.force, e.speed, e.blowing, e.stamp)
    })
    
    const options = {
      // Way better performance if animation is diabled
      animation: false,
      events: [],
      elements: {
        // Line tension to 0 disables smoothing of curves, better performance
        line: {tension: 0},
        point:{radius: 0}
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            maxTicksLimit: 10,
            maxRotation: 0, 
            fontSize: 14
          }
        }],
        yAxes: [
          {
            id: 'y-axis-force',
            display: true,
            position: 'left',
            ticks: {
              min: 0, max: 150, stepSize: 15, fontColor: '#F88181', fontSize: 14
            },
          },
          {
            id: 'y-axis-speed',
            display: true,
            position: 'right',
            gridLines: {drawOnChartArea: false},
            ticks: {
              min: 0, max: 100, stepSize: 10, fontColor: '#7AC9A8', fontSize: 14
            }
          },
          {
            id: 'y-axis-blowing',
            display: true,
            position: 'right',
            gridLines: {drawOnChartArea: false},
            ticks: {
              min: 0, max: 20, stepSize: 2, fontColor: '#6AA6E6', fontSize: 14
            }
          },
          {
            id: 'y-axis-max-force',
            display: false,
            position: 'right',
            gridLines: {drawOnChartArea: false},
            ticks: {
              min: 0, max: 150, stepSize: 1, fontColor: 'rgba(248, 129, 129, 0.1)', fontSize: 14
            }
          }
        ]
      }
    }

    if (this.props.needCrashTest) {
      options.scales.xAxes[0].type = 'time'
      options.scales.xAxes[0].time = {
        unit: 'second',
        displayFormats: {
          second: 'HH:mm:ss'
        }
      }
    }

    if (this.props.maxForce !== undefined) {
      const max = Math.round(this.max * 1.2)
  
      options.scales.yAxes[0].ticks.max = max
      options.scales.yAxes[3].ticks.max = max
      this.props.updateMax(max)
    }

   this.myChart = new Chart(canvas, {type: 'line', data, options})

   if (this.props.logContainerRef) {
     this.props.logContainerRef(canvas)
   }
  }

  update(data, distance, force, speed, blowing, stamp) {

    if (!stamp) {
      if (distance > this.maxDistance && speed !== null) {
        this.maxDistance = distance
        if (stamp) {
        data.labels.push(stamp)
        } else {
          data.labels.push(distance)
        }
      }
    }
    const x = stamp ? stamp : distance
    data.datasets[0].data.push({x, y: force})
    data.datasets[1].data.push({x, y: speed})
    data.datasets[2].data.push({x, y: blowing})

  }

  drawMaxForce() {
    if (this.myChart) {
      const data = this.myChart.data
      data.datasets[3].data = data.datasets[0].data.map((e) => {
        return {x: e.x, y: this.props.maxForce}
      })
      data.datasets[3].fill = 'end'
      data.datasets[3].backgroundColor = 'rgba(248, 129, 129, 0.06)'
    }
  }

  render () {
    // const styleAdd = this.props.logContainerRef ? {display : 'none'} : {}
    // DEBUG: show/hide graph in reports page
    const styleAdd = this.props.logContainerRef ? {} : {}
    const androidStatusBarHeight = 34
    // Don't ask me the why of the minus 1
    const height = this.props.height - 1 - androidStatusBarHeight
    return (
      <div className = 'content-center-and-right relative' style = {styleAdd}>
        <canvas width={this.props.width} height={height} id='myChart'></canvas>
      </div>
    )
  }
}

