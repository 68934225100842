import React from 'react'

import en from './en.json'
const text = en


module.exports = class Menu extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      categories: ['report', 'metrics', 'logs']
    }
  }

  render() {

    const LinkList = this.state.categories.map((category, index) => {
      let i = this.props.categoryIndex
      let n = this.state.categories.indexOf(category)
      let color = i === n ? "secondary-color" : "light-color"
      return (
        <h3
          className = {color + " menu-item"}
          key={"linkto"+category}
          onClick={this.props.goto(`/settings/${category}`)}>
          {en.categories[index]}
        </h3>
      )
    })

    return (
      <div className = "content-left pos-align-top">
        {LinkList}
      </div>
    )
  }
}
