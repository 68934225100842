import React from 'react'

export const WaitIcon = (props) => {
  return (
    <svg id="waitIcon" viewBox="0 0 84 91" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs></defs>
      <path fill="#002169" d="M17.0625982,18.1777739 C30.5931239,5.93585695 50.8421265,5.74867167 64.5597965,16.9236326 L53.6867184,17.3354402 C52.2831369,17.3915958 51.1977005,18.5521445 51.2538438,19.9560341 C51.309987,21.3224866 52.4328523,22.3894426 53.7802905,22.3894426 C53.8177193,22.3894426 53.8364338,22.3894426 53.8738626,22.3894426 L70.5671254,21.7717312 C71.9332781,21.7155756 73,20.6111825 73,19.24473 L73,19.0575447 C73,19.0201077 73,19.0013891 73,18.9639521 L73,18.9452336 L72.3824241,2.4354923 C72.3262809,1.03160274 71.1472724,-0.0540718578 69.7624053,0.00208372474 C68.3588238,0.0582393073 67.2733874,1.21878801 67.3295307,2.62267758 L67.7225335,12.9740233 C60.929199,7.41462062 52.5638532,4.19503389 43.6183604,3.74578923 C32.5394236,3.1842334 21.8909186,6.98409449 13.6752881,14.4340684 C2.37177836,24.673103 -2.41911319,40.2469179 1.17405547,55.0719917 C1.45477178,56.2325404 2.48406488,57 3.62564451,57 C3.83150313,57 4.01864734,56.9812815 4.22450596,56.9251259 C5.57194421,56.5881924 6.41409312,55.2217399 6.07723355,53.8740059 C2.93321097,40.8459108 7.12524108,27.1626671 17.0625982,18.1777739 Z"></path>
      <path fill="#002169" d="M82.8300239,35.9275201 C82.4943348,34.5797231 81.1329289,33.73735 79.7901725,34.0742993 C78.447416,34.4112485 77.6081932,35.7777648 77.9438823,37.1255618 C81.0956301,50.1542654 76.8995162,63.8381481 66.9966874,72.8234609 C60.0404629,79.1318993 51.312546,82.23932 42.6219278,82.23932 C34.322947,82.23932 26.0426155,79.4126903 19.366132,73.8343086 L30.3319764,72.8421803 C31.7120316,72.7111445 32.7377484,71.4943834 32.6072026,70.0904282 C32.4766568,68.6864731 31.2644461,67.6756254 29.8657415,67.8066612 L13.2864292,69.3042134 C11.9063739,69.4352492 10.8806572,70.6520103 11.011203,72.0559654 L12.5031546,88.6975136 C12.6150509,90.0078717 13.7153653,91 15.0021735,91 C15.0767711,91 15.1513687,91 15.2259663,90.9812806 C16.6060215,90.8502448 17.6317383,89.6334837 17.5011925,88.2295285 L16.6060215,78.1023322 C23.375752,83.6058363 31.6747328,86.806854 40.5331956,87.2561197 C41.2418726,87.2935585 41.9505496,87.3122779 42.6405772,87.3122779 C52.916394,87.3122779 62.6886771,83.5309587 70.3722279,76.5673413 C81.6364626,66.3278285 86.4107078,50.7720056 82.8300239,35.9275201 Z"></path>
    </svg>
  )
}
