import './style.scss'

import React from 'react'
import {ButtonHome} from '../../util/ButtonHome'
import {withRouter} from 'react-router-dom'
import en from './en.json'
import IsOnline from '../../util/IsOnline'
import Menu from './Menu'

import server from '../../util/server'
import {dateAndHoursToString} from '../../util/tools'

const text = en


const ReportLine = (props) => {
  const job = props.job
  const date = dateAndHoursToString(job.date, 'en')
  const unrolled = job.unrolled && job.unrolled !== '0m' ?  job.unrolled : null
  const jobs = job.numberOfJobs !== '0' ? job.numberOfJobs : null
  return (
    <div
      className ={ "report-line grid-report-line"}
      onClick =  {() => props.handleClick(props.jobId)}>
      <h3 className = "item-grid-report-line-tl pos-align-center">
        Launch #{job.launchNumber}
      </h3>
      <p className = "item-grid-report-line-tr  pos-align-center pos-justify-right">
        {job.date ? date : ''}
      </p>
      <p className = "item-grid-report-line-ml pos-align-center light-color">
        {unrolled ? `${unrolled} unrolled` : ''}
      </p>
      <p className = "item-grid-report-line-bl pos-align-center light-color">
        {job.uptime ? `${job.uptime} uptime` : ''}
      </p>
      <p className = "item-grid-report-line-mr pos-align-center pos-justify-right warning-color">
        {job.error !== 0 ? `${job.error} errors` : ''}
      </p>
      <p className = "item-grid-report-line-br pos-align-center pos-justify-right light-color">
        {jobs ? `${jobs} jobs` : ''}
      </p>
    </div>
  )
}

class Logs extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      categoryIndex: 2,
      logs: [],
      jobs: [],
      selected: null,
      loaded: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.close = this.close.bind(this)

    this.gotoHome = this.gotoHome.bind(this)
  }

  async componentDidMount() {
    const jobs = []
    let logs = []
    try {
      const response = await server.getLogs()
      const reader = response.body.getReader()
      const {count, size} = await processStream(reader)
      console.log({count, size})

      async function processStream(reader, size = 0, count = 0, lastLine = '') {
        try {
          const {value, done} = await reader.read()
          if (done) return {count, size}
          const joinedLines = new TextDecoder().decode(value)
          const rawLines = (lastLine + joinedLines).split(/\r?\n/)
          lastLine = rawLines.pop()
          const filteredLines = rawLines
            .filter((l) => !l.includes('systemd['))
            .filter((l) => !l.includes('Logs begin at'))
            .filter((l) => !l.includes('-- Reboot'))
          const strippedLines = filteredLines.map((l) => {
            return l.split(']:')[1].trimStart()
          })
          logs = logs.concat(strippedLines)
          return processStream(reader, size + value.length, count + 1, lastLine)
        } catch (error) {
          return console.error(error)
        }
      }

      let jobId = -1
      logs.forEach((line) => {
        if (line === '----------------ON--------------') {
          jobId += 1
          jobs.push({lines: [], error: 0})
        } else {
          if (jobId === -1) return
          if (line.includes('Launch Number: ')) {
            jobs[jobId].launchNumber = line.split('Launch Number: ')[1]
          }
          if (line.includes('Uptime: ')) {
            jobs[jobId].uptime = line.split('Uptime: ')[1]
          }
          if (line.includes('Unrolled distance: ')) {
            jobs[jobId].unrolled = line.split('Unrolled distance: ')[1]
          }
          if (line.includes('Number of jobs done: ')) {
            jobs[jobId].numberOfJobs = line.split('Number of jobs done: ')[1]
          }
          if (line.includes('setting time to ') && !jobs[jobId].date) {
            jobs[jobId].date = line.split('setting time to ')[1]
          }
          if (line.includes('ERROR')) jobs[jobId].error = jobs[jobId].error + 1
          jobs[jobId].lines.push(line)

        }
      })

      this.setState({logs, jobs: jobs.reverse(), loaded: true})
    } catch (error) {
      console.error(error)
    }
  }

  gotoHome() {
    this.goto('/')()
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  handleClick(jobId) {
    this.setState({selected: jobId})
    this.refs.scrollElement.scrollTop = 0
  }

  close() {
    this.setState({selected: null})
  }

  render() {
    const jobs = this.state.jobs

    const jobList = jobs.map((job, index) => {
      return (
        <ReportLine
          key={job.launchNumber}
          job={job}
          jobId={index}
          handleClick={this.handleClick}/>
      )
    })

    let lines = ''
    if (jobs.length && this.state.selected !== null) {
      lines = this.state.jobs[this.state.selected].lines.map((line, i) => {

        let classes = 'monospace '
        if (line.includes('INFO')) classes += 'info-color'
        if (line.includes('LOG')) classes += 'log-color'
        if (line.includes('DEBUG')) classes += 'log-color'
        if (line.includes('ERROR')) classes += 'warning-color'

        return <div className={classes} key={i}>{line}</div>
      })
    }

    let closedButton = (
      <div className = "footer-right pos-align-bot sticky-bot"
        onClick={this.close}>
        <div className={"btn btn--raised"}> Close </div>
      </div>
    )

    const isConnected = this.props.isOnline === 'server'
    const noConnection = <div><span className="warning-color">No connection </span><span>to the machine.</span></div>

    return (
      <div className = "grid-main">
        <div className = "header-right pos-align-center">
          <ButtonHome onClick = {this.gotoHome}/>
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div
          className = "header-left-and-center pos-align-bot">
          <h2 className = "secondary-color">
            Settings
            </h2>
        </div>
        <Menu
          categoryIndex={this.state.categoryIndex}
          goto={this.goto.bind(this)}/>
        <div
          ref="scrollElement"
          className="content-center content--scrollable">
          <div>{!isConnected ? noConnection : ''}</div>
          <div>{isConnected && !this.state.loaded ? 'Loading...' : ''}</div>
          <div>{this.state.selected === null ? jobList : ''}</div>
          <div>{this.state.selected !== null ? lines : ''}</div>
        </div>
        {this.state.selected !== null ? closedButton : ''}
      </div>
    )
  }

}


export default withRouter(Logs)
