const getMachineOrigin = require('./get-machine-origin')
const apiAddress = getMachineOrigin()
import ky from 'ky'


module.exports = {
  getReports,
  getFullReports,
  deleteReportById,
  getReportById,
  getStatus,
  sendParameters,
  getLogs,
  getMetrics
}


function getReports() {
  return fetch(`${apiAddress}/getReports`, {method: 'GET'})
    .then((data) => data.json())
    .then((parsed) => parsed.reports)
}

function getFullReports(reports) {
  const promises = []
  reports.forEach((report) => promises.push(getReportById(report.reportId)))
  return Promise.all(promises)
}

function deleteReportById(id) {
  return fetch(`${apiAddress}/deleteReport`, {
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {"Content-Type": "application/json"}
  })
}

function getReportById(reportId) {
  return fetch(`${apiAddress}/getReport`, {
    method: 'POST',
    body: JSON.stringify({reportId}),
    headers: {"Content-Type": "application/json"}
  })
    .then((raw) => raw.json())
    .then((response) => response.report)
}

function getStatus() {
  return ky.get(`${apiAddress}/isOnline`, {timeout: 1000})
    .then((raw) => raw.json())
}

function sendParameters(data, url) {
  return fetch(apiAddress + url, {
    method: 'POST',
    body: JSON.stringify({data}),
    headers: {"Content-Type": "application/json"}
  })
    .then((raw) => raw.json())
    .then((response) => response.newReport)
}

function getLogs() {
  return fetch(`${apiAddress}/logs`, {method: 'GET'})
    .then((response) => {
      if (!response.ok) {
        const error = new Error(response.statusText)
        error.status = 404
        throw error
      }
      else return response
    })
}

function getMetrics() {
  return ky.get(`${apiAddress}/metrics`, {timeout: 2000})
    .then((raw) => raw.json())
}
