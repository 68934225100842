import React from 'react'
import {withRouter} from 'react-router-dom'
import en from './en.json'
const text = en
import IsOnline from '../../../util/IsOnline'
import indexedDB from '../../../util/indexedDB'


class Validation extends React.Component {

  constructor(props) {
    super(props)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoSave = this.gotoSave.bind(this)
    this.gotoCancel = this.gotoCancel.bind(this)
    this.gotToMethod = this.gotToMethod.bind(this)

    this.state = {needCrashTest: false}
  }

  gotoCancel() {
    this.goto('/parameters/form/general')()
  }
  
  gotoSave() {
    this.goto('/parameters/save-as-favorite')()
  }

  gotToMethod() {
    this.goto('/parameters/method')()
  }

  gotoHome() {
    this.goto('/')()
  }

  async gotoNext() {
    const favorite = this.props.parameters
    try {
      await indexedDB.saveFavorite(favorite)
      this.gotToMethod()
    } catch (error) {
      console.error('Validation::gotoNext', error)      
    }
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  render() {
    let action = (
    <div
      className={"btn btn--raised disable item-grid-modal-bottom-right pos-align-center"}>
      {text.buttonStart}
    </div>
    )

    let message = (
    <div className = "item-grid-modal-middle pos-align-center">
      <h3 className = "text-center warning-color">{text.noConnection}</h3>
    </div>
    )

    if (this.props.isOnline === 'server') {
      action = (<div className={"btn btn--raised item-grid-modal-bottom-right pos-align-center"} onClick={this.gotoNext}> {text.buttonStart} </div>)
      message = ''
    }

    return (
      <div className = "grid-main background-blue">
        <div className = "header-right">
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div className = "content-center pos-align-top grid-modal modal">
          <div className="btn btn--flat item-grid-modal-close btn--closemodal" onClick={this.gotoCancel}> X </div>
          <div className = "item-grid-modal-top pos-align-center">
            <h2 className = "text-center">{text.text}</h2>
          </div>
          {message}
          <div className={"btn btn--flat item-grid-modal-bottom-left"} onClick={this.gotoSave}> {text.buttonSave} </div>
          {action}
        </div>
      </div>
    )
  }
}

export default withRouter(Validation)
