import React from 'react'
import {withRouter} from 'react-router-dom'
import IsOnline from '../../../util/IsOnline'
import en from './en.json'
const text = en


class Method extends React.Component {
  constructor (props) {
    super(props)
    this.gotToRecording = this.gotToRecording.bind(this)
    this.gotToCrashTest = this.gotToCrashTest.bind(this)
    this.gotoCancel = this.gotoCancel.bind(this)

    const fmaxSource = this.props.fmaxSource
    if (fmaxSource === 'From Datasheet') this.gotToRecording()
    const fmax = this.props.fmax
    if (fmaxSource === 'CrashTest' && !fmax) this.gotToCrashTest()
  }

  gotoCancel () {
    this.goto('/parameters/form/cable')()
  }

  gotToRecording () {
    this.props.setCrashTestMode(false)
    this.props.sendParametersToMachine(false)
    this.goto('/recording/chart')()
  }

  gotToCrashTest () {
    this.props.setCrashTestMode(true)
    this.props.sendParametersToMachine(true)
    this.goto('/recording/chart')()
  }

  goto (href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  render() {

    const recordingButton = (
      <div
        className={"btn btn--raised item-grid-modal-bottom-right pos-align-center"}
        onClick={this.gotToRecording}>
        {text.buttonStart}
      </div>
    )
    const crashTestButton = (
      <div
        className={"btn btn--flat item-grid-modal-bottom-left pos-align-center"}
        onClick={this.gotToCrashTest}>
        {text.buttonCrashTest}
      </div>
    )

    return (
      <div className = "grid-main background-blue">
        <div className = "header-right">
          <IsOnline isOnline = {this.props.isOnline}/>
        </div>
        <div className = "content-center pos-align-top grid-modal modal">
          <div className="btn btn--flat item-grid-modal-close btn--closemodal" onClick={this.gotoCancel}> X </div>
          <div className = "item-grid-modal-top pos-align-center">
            <h2 className = "text-center">{text.text}</h2>
          </div>
          {crashTestButton}
          {recordingButton}
        </div>
      </div>
    )
  }
}

export default withRouter(Method)
