import './recording-component.scss'

import React from 'react'

const IsOnline = (props) => {
  const {isOnline} = props;
  let color = "red";
  switch (isOnline) {
    case "no":
      color = "red"
      break;
    case "wifi":
      color = "orange"
      break;
    case "server":
      color = "green"
      break;
  }
  return (
    <div className = {"isConnectedStatus isConnectedStatus-"+color}>
    </div>
  )
}

export default IsOnline;
