import './style.scss'

import React from 'react'
import {withRouter} from 'react-router-dom'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import Graphs from './Graphs'
const server = require('../../../util/server')
import indexedDB from '../../../util/indexedDB'


const Title = () => {
  return (
    <div className = "header-left pos-align-center grid-status">
      <h3 className = {"item-grid-status--right pos-align-center secondary-color uppercase recordingStatus text-force"}>Max Force</h3>
    </div>
  )
}

const SetButton = (props) => {
  return (
    <div className = {"btn btn--raised grid-btn-home header-3 pos-align-center"} onClick = {props.onClick}>
      <div className = "item-grid-btn-home-right pos-justify-left">
        Set
      </div>
    </div>
  )
}

const CancelButton = (props) => {
  return (
    <div className = {"btn btn--flat grid-btn-home header-4 pos-align-center"} onClick = {props.onClick}>
      <div className = "item-grid-btn-home-right pos-justify-left">
        Cancel
      </div>
    </div>
  )
}

class MaxForce extends React.Component {
  constructor (props) {
    super(props)
    this.set = this.set.bind(this)
    this.cancel = this.cancel.bind(this)
    this.state = {
      report: {data: []},
      maxForce: this.props.fmax || 5,
      max: 150
    }
    this.reportId = this.props.reportId
    this.onSliderChange = this.onSliderChange.bind(this)
    this.updateMax = this.updateMax.bind(this)
  }

  async componentDidMount() {
    history.pushState(null, null, location.href)
    window.onpopstate = function onpopstate() {history.go(1)}

    if (!this.reportId) return

    try {
      const report = await server.getReportById(this.reportId)
      this.setState({report})
    } catch (error) {
      console.error(`[ERROR]: Cannot fetch report ${this.reportId}`, error)
    }
  }

  async set() {
    const event = {target: {value: this.state.maxForce, name: 'fmax'}}
    this.props.updateParameter(event, 'cable', 'text')
    this.props.initiate()
    const favorite = this.state.report.parameters
    favorite.cable.fmax = this.state.maxForce
    try {
      await indexedDB.saveFavorite(favorite)
      this.goto('/parameters/method')()
    } catch (error) {
      console.error('MaxForce::set', error)      
    }
  }

  cancel() {
    this.props.initiate()
    this.goto('/parameters/form/cable')()
  }

  goto(href) {
    return () => {
      this.props.history.push(href)
      this.props.updateLocation(href)
    }
  }

  onSliderChange(maxForce) {
    this.setState({maxForce})
  }

  updateMax(max) {
    this.setState({max})
  }

  render () {
    const plot = this.state.report.data.length ? <Graphs recordedData = {this.state.report.data} width = {780} height = {460} needCrashTest = {true} maxForce={this.state.maxForce} updateMax={this.updateMax}/> : ''

    const marks = {}
    marks[this.state.maxForce] = {
      style: {
        color: '#F88181',
        marginLeft: 20,
        fontSize: 20
      },
      label: <strong>{this.state.maxForce}N</strong>,
    }

    return (
      
      <div className = "grid-main">
        <Title/>

        <div className = "content-left grid-sidebar">
          <div className = "item-grid-sidebar-1-6">
            <div style={{width: '100%', height: 391, marginLeft: 70, marginTop: 7}}>
              <Slider
                onChange={this.onSliderChange}
                vertical
                included={false}
                min={0}
                max={this.state.max}
                step={1}
                defaultValue={this.state.maxForce}
                marks={marks}
                trackStyle={{
                  backgroundColor: '#F88181', width: 3
                }}
                handleStyle={{
                  borderColor: '#F88181', borderWidth: 3, marginLeft: -14, width: 32, height: 32
                }}
                railStyle={{ backgroundColor: '#e5e5e5', width: 5}}
              />

            </div>
          </div>
        </div>


        {plot}
        <SetButton  onClick = {this.set}/>
        <CancelButton onClick = {this.cancel}/>
      </div>
    )
  }
}


export default withRouter(MaxForce)
